.Index{
    min-height: 100vh;
    background: linear-gradient(317deg, #0167F3 0%, #012292 100%);
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    .content{
        width: 1300px;
        height: 730px;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .title{
            font-size: 59px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #FFFDFF;
            line-height: 45px;
            -webkit-background-clip: text;
            margin-bottom: 159px;
        }
        .inner{
            display: flex;
            position: relative;
            .tips{
                position: absolute;
                top: -38px;
                left: 20px;
                font-size: 18px;
                font-weight: 600;
                color: #FFFDFF;
                font-family: PingFang SC-Semibold, PingFang SC;
            }
            .input{
                width: 507px;
                height: 74px;
                background: #FFFDFF;
                border-radius: 17px 17px 17px 17px;
                box-sizing: border-box;
                padding: 25px 45px;
                border: none !important;
                font-size: 20px;
                font-family: Source Han Sans CN-Regular, Source Han Sans CN;
                font-weight: 400;
                line-height: 24px;
                &:focus{
                    border: none;
                }
            }
            .btn{
                width: 190px;
                height: 74px;
                background: #006BF9;
                border-radius: 17px;
                border: none;
                display: flex;
                align-items: center;
                margin-left: 8px;
            }
            .font{
                font-size: 24px;
                font-family: Source Han Sans CN-Medium, Source Han Sans CN;
                font-weight: 500;
                color: #FFFDFF;
                line-height: 35px;
                -webkit-background-clip: text;
                margin-left: 32px;
            }
            .r{
                width: 34px;
                height: 34px;
                border-radius: 50%;
                margin-left: 31px;
                background-color: white;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}


@media screen and (max-width: 425px){
    .Index{
        .content{
            width: 100%;
            height: 100%;
            overflow: hidden;
            .title{
                text-align: center;
                font-size: 22px;
            }
            .inner{
                width: 80%;
                margin: 0 auto;
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                .input{
                    width: 100%;
                    height: 40px;
                    padding: 5px 15px;
                    margin-bottom: 50px;
                    font-size: 16px;
                    border-radius: 5px;
                }
                .btn{
                    width: 50%;
                    height: 60px;
                    border-radius: 8px;
                    font-weight: 400;
                    .font{
                        font-size: 16px;
                        font-weight: 400;
                        margin-left: 20px;
                    }
                    .r{
                        width: 30px;
                        height: 30px;
                        margin-left: 30px;
                    }
                }
            }
        }
    }

}

@media screen and (min-width:1420px){
    body{
        min-width: 900px;
    }
    
}
