// 字体
$fstyle: PingFang SC-中粗体, PingFang SC;
$infoFstyle: PingFang SC-常规体, PingFang SC;

#_bg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.generate{
    width: 100%;
    min-height: 100vh;
    padding-top: 40px;
    background-color: #0496FF;
}

.ant-modal-mask{
    background-color: rgba(0, 0, 0, 0.75);
}

.container{
    max-width: 1295px;
    // height: 640px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    box-sizing: border-box;
    // 标题
    .title{
        width: 100%;
        margin-left: 66px;
        font-size: 22px;
        font-weight: normal;
        color: #000000;
        line-height: 27px;
        -webkit-background-clip: text;
        text-align: left;
        margin-bottom: 40px;
    }
    // 输入部分
    .inner{
        padding-top: 57px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        border-radius: 9px;
        background-color: white;

        .ant-form-item:nth-child(1){
            .ant-form-item-control-input-content{
                .ant-form-item:first-child{
                    margin-right: 8px !important;
                }
                .ant-form-item:last-child{
                    margin-right: 0 !important;
                }
            }
        }
        .form > .ant-form-item{
            margin-bottom: 24px;
        }
        .ant-form-item{
            margin-bottom: 0;
        }
        .ant-row{
            align-items: center;
        }
        .ant-upload-list{
            display: none;
        }
        // 上传logo
        .upload-img{
            display: flex;
            align-items: flex-end;
            gap: 15px;
            .img-box, .img{
                width: 160px;
                height: 104px;
                display: flex;
                align-items: center;
                border: 1px dashed rgba($color: #000000, $alpha: 0.15);
                img{
                    max-height: 100%;
                    max-width: 100%;
                }
            }
            .img-info{
                display: flex;
                gap: 15px;
                align-items: center;
            }
            .tips{
                font-size: 12px;
                color: rgba(0,0,0,0.45);
            }
            .img-box{
                display: flex;
                align-items: center;
                justify-content: center;

                font-size: 24px;
                font-weight: 200;
                color: #A1AAB7;
            }
        }
        .clear{
            position: absolute;
            right: 57px;
            top: 44px;
            width: 24px;
            height: 24px;
            cursor: pointer;
            background-color: rgba(255, 255, 255, 0);
        }
        .btn{
            margin-top: 60px;
            width: 342px;
            height: 50px;
            background: #000100;
            border-radius: 8px;
            font-size: 20px;
            font-weight: normal;
            color: #FFFFFF;
            line-height: 24px;
        }
    }
    .form{
        width: 800px;
        opacity: 1;
        padding: 40px;
        padding-top: 0;
        .ant-form-item-label{
            text-align: left;
            font-size: 18px;
            font-weight: normal;
            line-height: 22px;
            -webkit-background-clip: text;
            line-height: 52px;
            label{
                color: #607082;
                font-size: 18px;
            }
        }
        .ant-select-selection-item{
            font-size: 16px;
            color: #303031;
        }
        .ant-input-group-addon{
            border-radius: 6px;
            background-color: #F2F4F5;
            border: none;
        }
        .phone input{
            width: calc(100% - 14px);
            margin-left: 14px;
        }
        .ant-input{
            background: #F2F4F5;
            border-radius: 6px;
            border: none;
            padding: 15px;
            &:focus{
                box-shadow: none;
            }
        }
    }
    // 预览部分
    .bg{
        width: 485px;
        height: 531px;
        border-radius: 12px;
        background-color: #FFFFFF;
        padding: 43px 8px 21px;
        p{
            margin: 0;
        }
        .title{
            margin-left: 33px;
            margin-bottom: 20px;
            font-size: 21px;
            font-weight: 600;
        }
        .card{
            width: 100%;
            height: 350px;
            border-radius: 12px;
            background-color: rgba($color: #A1AAB7, $alpha: 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .canvans{
            width: 375px;
            height: 237px;
            background: url('../assets/bg.png') no-repeat;
            background-size: cover;
            position: relative;
            opacity: 1;
            background-color: #fff;
            box-shadow: 16px 10px 30px #969799;
        }
        .custom-icon{
            width: 7px;
            height: 7px;
            position: absolute;
            left: 26px;
        }
        #icon-name{
            top: 141px;
        }
        #icon-addr{
            top: 155px;
        }
        #icon-tel{
            top: 166px;
        }
        #icon-url{
            top: 180px;
        }
        #icon-email{
            top: 193px;
        }
        .img{
            position: absolute;
            top: 29px;
            right: 23px;
            width: 71px;
            height: 71px;
        }
        
        .logo{
            position: absolute;
            top: 26px;
            left: 28px;
            font-size: 25px;
            font-weight: bold;
            line-height: 30px;
            img{
                height: 22px;
            }
        }
        .name{
            position: absolute;
            top: 74px;
            left: 26px;
            font-size: 26px;
            line-height: 23px;
            span{
                font-size: 15px;
                // position: absolute;
                color: #0496FF;
            }
        }
        .middle{
            font-size: 15px !important;
        }
        .job{
            position: absolute;
            top: 100px;
            left: 26px;
            font-size: 9px;
            color: #333; 
        }
        .text{
            position: absolute;
            color: #000; 
            font-size: 8px;
            left: 40px;
        }
        .corp{
            top: 135px;
            font-weight: 500;
            font-size: 11px;
        }
        .address{
            top: 151px;
        }
        .phone{
            top: 164px;
        }
        .url{
            top: 177px;
        }
        .email{
            top: 190px;
        }
    }
    .btn-create{
        width: 100%;
        height: 68px;
        margin-top: 50px;
        border-radius: 9px;
        background-color: #000;
        border: none;
        box-shadow: none;
        color: #fff;
        font-size: 24px;
        font-weight: 400;
    }
}


.down{
    width: auto !important;
    display: flex;
    justify-content: center;
    .ant-modal-content{
        background-color: rgba(255, 255, 255, 0);
        box-shadow: none;
    }
    .ant-modal-confirm-content{
        margin: 0;
    }
    .ant-modal-confirm-btns{
        display: none;
    }
    .box{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .shadow{
        box-shadow: 0px 8px 10px 0px rgba(0,0,0,0.1);
    }
    #canvans{
        position: fixed;
        width: 675px;
        height: 408px;
        position: relative;
        opacity: 1;
        color: #332C2B;
        .custom-icon{
            width: 12px;
            height: 12px;
            position: absolute;
            left: 44px;
        }
        #icon-name{
            top: 230px;
        }
        #icon-addr{
            top: 256px;
        }
        #icon-tel{
            top: 280px;
        }
        #icon-url{
            top: 303px;
        }
        #icon-email{
            top: 327px;
        }
        // 二维码
        .img{
            position: absolute;
            top: 49px;
            right: 46px;
            width: 122px;
            height: 122px;
        }
        // logo
        .logo{
            position: absolute;
            top: 50px;
            left: 44px;
            font-size: 25px;
            font-weight: bold;
            line-height: 30px;
            img{
                height: 46px;
            }
        }
        .name{
            position: absolute;
            top: 128px;
            left: 44px;
            font-size: 47px;
            line-height: 40px;
            span{
                font-size: 27px;
                // position: absolute;
                color: #0496FF;
            }
        }
        .middle{
            font-size: 29px !important;
        }
        .job{
            position: absolute;
            top: 174px;
            left: 44px;
            font-size: 16px;
            color: #333; 
        }
        .text{
            position: absolute;
            color: #000; 
            font-size: 15px;
            left: 67px;
        }
        .corp{
            top: 220px;
            color: #000;
            font-weight: 500;
            font-size: 20px;
        }
        .address{
            top: 251px;
        }
        .phone{
            top: 274px;
        }
        .url{
            top: 297px;
        }
        .email{
            top: 320px;
        }
    }
    .download{
        width: 342px;
        height: 70px;
        margin-top: 84px;
        font-size: 20px;
        font-weight: normal;
        color: #000100;
        line-height: 24px;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        span{
            margin-left: 11px;
        }
    }
}


@media screen and (max-width: 425px){
    .generate{
        padding: 36px 39px 127px 34px;
        background-color: #fff;
        .container{
            max-width: 100%;
            .inner{
                width: calc(100vw - 34px - 39px);
                overflow: hidden;
                padding: 0;
                border-radius: 0;
                .title{
                    margin: 0;
                    margin-left: 5px;
                    margin-bottom: 24px;
                    font-size: 17px;
                    font-weight: 600;
                    line-height: 20px;
                }
                .clear{
                    width: 16px;
                    height: 16px;
                    right: 39px;
                    top: 0;
                }
            }
            .form{
                width: 100%;
                padding: 0;
                .form > .ant-form-item{
                    margin-bottom: 16px;
                }
                .ant-select-selection-item{
                    font-size: 12px;
                }
                .ant-input{
                    padding: 7px 10px;
                    font-size: 12px;
                }
                .ant-form-item-label {
                    padding-bottom: 17px;
                    line-height: 19px;
                    label{
                        height: 19px;
                        line-height: 19px;
                        font-size: 13px;
                        font-weight: 600;
                    }
                }
                .upload-img{
                    gap: 9px;
                    .img-box{
                        width: 95px;
                        height: 62px;
                        font-size: 16px;
                    }
                    .img-info{
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 10px;
                    }
                    button{
                        height: 23px;
                        padding: 0 7px;
                        font-size: 12px;
                    }
                    .tips{
                        float: left;
                        font-size: 12px;
                    }
                }
            }
            .create{
                width: 100%;
                padding: 16px 36px 26px;
                position: fixed;
                bottom: 0;
                left: 0;
                background-color: #fff;
                .btn-create{
                    width: 100%;
                    height: 50px;
                    margin-top: 0;
                    border-radius: 10px;
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
        .bg{
            display: none;
        }
    }
    .down-mobile{
        .ant-modal-body{
            padding: 0;
        }
        #canvans{
            width: 23.5625rem;
            height: 14.25rem;
            .custom-icon{
                width: .4375rem;
                height: .4375rem;
                left: 1.5625rem;
            }
            #icon-name{
                top: 7.875rem;
            }
            #icon-addr{
                top: 8.75rem;
            }
            #icon-tel{
                top: 9.5625rem;
            }
            #icon-url{
                top: 10.375rem;
            }
            #icon-email{
                top: 11.25rem;
            }
            // 二维码
            .img{
                top: 1.75rem;
                right: 1.625rem;
                width: 4.25rem;
                height: 4.25rem;
            }
            // logo
            .logo{
                top: 1.75rem;
                left: 1.5625rem;
                font-size: 1.25rem;
                font-weight: 400;
                line-height: 1.25rem;
                p{
                    margin-bottom: 0;
                }
                img{
                    height: 1.4375rem;
                }
            }
            .name{
                top: 4.4375rem;
                left: 1.5625rem;
                font-size: 1rem;
                line-height: 1.4375rem;
                span{
                    font-size: .5rem;
                }
            }
            .job{
                top: 5.875rem;
                left: 1.5625rem;
                font-size: .5625rem;
            }
            .text{
                font-size: .5rem;
                left: 2.375rem;
            }
            .corp{
                top: 7.75rem;
                color: #000;
                font-weight: 500;
            }
            .address{
                top: 8.55rem;
            }
            .phone{
                top: 9.4rem;
            }
            .url{
                top: 10.2rem;
            }
            .email{
                top: 11.1rem;
            }
        }
        .download{
            width: 15.1875rem;
            height: 3.125rem;
            margin-top: 4.375rem;
            font-size: 1rem;
            line-height: 1.5rem;
            border-radius: .375rem;
            span{
                margin-left: .6875rem;
            }
        }
    }
}